.clip-bg {
  background-size: cover;
  background-position: right;
  clip-path: ellipse(0 0, 200px 0, 0, 0);
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );

  clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 95%, 72% 100%, 0% 100%);
}

.section-3 img {
  height: 100%;
  clip-path: ellipse(0 0, 200px 0, 0, 0);
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 95%, 72% 100%, 0% 100%);
}

.section-para {
  display: flex;
  justify-content: center;

  flex-direction: column;
}

.section-btn p {
  transition: 0.3s ease;
}

.section-btn {
  text-decoration: none;
}

.section-btn span {
  font-size: var(--medium-text);
}

.section-btn p:hover {
  color: var(--white);
}

@media (max-width: 992px) {
  .section-3 .section-para {
    padding-top: 1.5rem;
    padding-bottom: 1.3rem;
  }

  .section-3 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
