@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,700;1,8..144,800;1,8..144,900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
  overflow-x: hidden;
  overflow-y: scroll !important;
  scroll-behavior: smooth;
  padding-right: 0 !important;
  /* font-family: "Roboto", sans-serif; */
  /* font-size: 1.6rem; */
}

html {
  font-size: 16px;
}

.nav-link {
  cursor: pointer !important;
}

:root {
  /* colors */
  --blue: rgb(53, 100, 144);
  --dark-blue: rgb(37, 82, 101);
  --green: rgb(140, 194, 38);
  --purple: #6b5e8b;
  --white: #fff;
  --black: #000;
  --grey: #666;
  --light-grey: #888;
  /* colors: hover */
  --blue-hover: #2b5379;
  --green-hover: #76a51f;

  /* font-sizes */
  --super-text: calc(1rem * 5);
  --massive-text: calc(1rem * 3);
  --big-text: calc(1rem * 2);
  --bs-navbar-toggler-font-size: 2rem !important;
  --medium3-text: calc(1rem * 1.4);
  --medium2-text: calc(1rem * 1.5);
  --medium-text: calc(1rem * 1.6);
  --para-text: calc(1rem * 1.15);
  --paraM-text: calc(1rem * 1.25);
  --para2-text: calc(1rem * 1.3);
  --small-text: 1rem;

  /* font-size */
  --big-para: 2rem;
  --hero-title: 3.5rem;
  --hero-para: 1rem;
  --para: 1.1rem;
  /*button para*/

  /* paddings and margin */
  --padding: 1rem;
  --margin: 1rem;

  /* positioning */
  --hero-title-top: 23%;

  /* font-family */
  --roboto: "Roboto Condensed", sans-serif;
  --lora: "lora", serif;
}

.app {
}

.whatsapp-wrapper {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 15px;
  right: 10px;
}

.whatsapp-wrapper img {
  width: 100%;
  height: 100%;
}
.navbar-toggler {
  border: 2px solid var(--green);
}

.navbar-toggler:hover {
  border: 2px solid var(--green);
}

.hamburger {
  color: var(--blue);
  font-size: 1.8rem !important;
}
